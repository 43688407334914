.inkassoMainFrame {
  background-image: url(/img/inkasso_landing_bg.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inkassoPopupFrameMainPage {
  background-color: white;
  max-width: 560px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.inkassoPopupFrame {
  background-color: white;
  max-width: 560px;
  /*height: 592px;*/
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex-shrink: 0;
}



.inkassoDivider {
  background-color: white;
  height: 3px;
  width: 100%;
  margin: 134px 0 60px;
}

.inkassoLinksWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
  flex-shrink: 0;
}

.inkassoLink a {
  color: white;
  font-size: 13px;
  margin: 0 10px 2px;
  font-family: Asap-SemiBold;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid white;
  padding-bottom: 2px;

}

.inkassoAvbryt {
  margin: 15px 0 80px 0px;
  display:flex;
  align-content: center;
  justify-content: center;
}

.inkassoAvbryt a{
  color: #00829a;
  font-size: 14px;
  margin: 0 10px 2px;
  font-family: Asap-SemiBold;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid #00829a;
  padding-bottom: 1px;
}

.inkassoBankIdElementsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px
}

.inkassoBankIdElement {
  width: 380px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.inkassoActionWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inkassoInfoText {
  width: 380px;
  font-family: Asap-SemiBoldItalic;
  color: #00325c;
  font-size: 32px;
  font-weight: 400;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 30px;
  text-shadow: 0px 0px 1px #00325c
}

.inkassoMobileLink {
  text-decoration:none;
  color: #2b85ac;
  border-radius: 10px;
}

.inkassoOtherLink {
  text-decoration:none;
  color: #2b85ac
}

.inkassoSsnField {
  margin-bottom: 40px;
}
.inkassoPrimaryButton {
  width: 100%;
  margin-bottom: 10px;
}
.inkassoCancelButton {
  width: 100%;
  margin-bottom: 80px;
}

/*Triggers when window size is <= 960px*/
@media (max-width: 959px) {
  .inkassoAuthOnlyDesktop {
    width: 100%;
    visibility:hidden;
    height: 0px;
  }
  .inkassoMainFrame {
    overflow-x: hidden;
    min-width: 320px;
  }
  .inkassoPopupFrameMainPage {
    background: rgba(255,255,255,0);
  }
  .inkassoSsnField {
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
  }
  .inkassoPrimaryButton {
    width: 100%;
    max-width: 335px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .inkassoCancelButton {
    width: 100%;
    max-width: 335px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 80px;
  }
  .inkassoBankIdElement {
    max-width: 380px;
    display: flex;
    min-width: 320px;
    width: 100%;
  }
  /* needed? */
  .body { 
    min-width: 320px;
  }
  .inkassoDivider {
    height: 2px;
    width: 80%;
    margin: 20px 10px 20px;
  }
  .inkassoInfoText {
    font-size: 24px;
  }
  .inkassoSpinnerWrapper {
    margin-top: 0px;
  }
}

/*Triggers when window size is >= 961px*/
@media not all and (max-width: 959px) {
  .inkassoAuthOnlyDesktop {
    width: 100%;
  }
  .inkassoAuthOnlyMobile {
    width: 0px;
    visibility:hidden;
    height: 0px;
    margin: 0 0 0 0;
  }
}
.inkassoFooter {
  color: white;
  margin: 30px 00px 2px 0px;
  font-family: Asap-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
}

.inkassoSpinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 10%
}
.inkassoInvoiceMainFrame {
  padding: 0;
  background-color: #f8f8f4;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.sveaLogoBlueWrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
  flex-shrink: 0;
}

.sveaLogoBlue {
  height: 50px;
  margin-top: 60px;
  margin-left: 60px;
  margin-bottom: 42px;
}

.inkassoInvoiceFrame {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-shrink: 0;
}

.inkassoGridContentsWrapper {
  display:flex;
  justify-content:center;
}

.inkassoInvoiceInfoWrapper {
  width: 335px;

}

.inkassoInvoiceHeader  {
  margin-bottom: 10px;
  font-family: Asap-SemiBold;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #434343;
}

.inkassoInvoiceDueDate {
  margin-bottom: 20px;
  font-family: Asap-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #434343;
}

.inkassoInvoiceDetailsFrame {
  margin-bottom:20px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  height: 295px;
}

.inkassoInvoiceDetailsHeader {
  
  font-family: Asap-SemiBold;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: #434343;
  padding-left: 20px;
  padding-top: 12px;
}

.inkassoInvoiceDetailsDivider {
  background-color: #ececec;
  height: 1px;
  width: 295px;
  border-radius: 3px;
  border: 1px solid #ececec
}

.inkassoInvoiceDetail {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.inkassoInvoiceDetailsLabel {
  margin-left: 20px;
  margin-bottom: 12px;
  margin-right: auto;
  font-family: Asap-SemiBold;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #434343;
}

.inkassoInvoiceDetailsValue {
  margin-right: 20px;
  font-family: Asap-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #434343;
}

.inkassoInvoiceTotal {
  margin-bottom: 10px;
  font-family: Asap-SemiBold;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.19;
  letter-spacing: normal;
  text-align: center;
  color: #00325c;
  height: 25px;
}

.inkassoInvoiceToPay {
  height: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 5;
  letter-spacing: normal;
  text-align: center;
  color: #434343;
}

.payButtonWrapper {
  margin-bottom: 10px;
}

.inkassoPdfWrapper {
  max-width:360px;
}

.inkassoPdfContainer {
  background: #ffffff;
  min-height: 100%;
  max-height: 504px;
  overflow: scroll;
  min-width: 360px;
}

.inkassoPdfPlaceholder {
  height: 504px;
  width: 360px;
  background-color: #ffffff;

}


.inkassoInvoiceDivider {
  background-color: #00829a;
  height: 3px;
  width: 100%;
  margin: 200px 0 60px;
  overflow:visible;
}

.inkassoInvoiceLinkBlue a{
  color: #00829a;
  font-size: 14px;
  margin: 0 10px 2px;
  font-family: Asap-SemiBold;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid #00829a;
  padding-bottom: 2px;

}

.inkassoFooterBlack {
  color: #434343;
  margin: 42px 00px 60px 0px;
  font-family: Asap-Medium;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
}

/*Triggers when window size is <= 960px*/
@media not all and (min-width: 960px) {
  .onlyDesktop {
  width: 100%;
  visibility:hidden;
  height: 0px;
  }
  .sveaLogoBlue {
    margin-left: 0px;
    margin-top: 100px;
    margin-bottom: 30px;
  }
  .inkassoInvoiceDivider {
    margin-top: 0px
  }
  .inkassoInvoiceFrame {
    margin-top: 40px;
  }
  }

  /*Triggers when window size is >= 961px*/
  @media (min-width: 960px) {
    .onlyMobile {
      visibility:hidden;
      height: 0px;
    }
    }
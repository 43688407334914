body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Asap-Regular';
  src: local('Asap-Regular'), url(./fonts/Asap-Regular.ttf);
}

@font-face {
  font-family: 'Asap-SemiBold';
  src: local('Asap-SemiBold'), url(./fonts/Asap-SemiBold.ttf);
}

@font-face {
  font-family: 'Asap-Bold';
  src: local('Asap-Bold'), url(./fonts/Asap-Bold.ttf);
}

@font-face {
  font-family: 'Asap-Italic';
  src: local('Asap-Italic'), url(./fonts/Asap-Italic.ttf);
}

@font-face {
  font-family: 'Asap-Medium';
  src: local('Asap-Medium'), url(./fonts/Asap-Medium.ttf);
}

@font-face {
  font-family: 'Asap-SemiBoldItalic';
  src: local('Asap-SemiBoldItalic'), url(./fonts/Asap-SemiBoldItalic.ttf);
}
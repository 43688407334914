.sveaLogoLeftWrapper {
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
  margin-left: 60px;
  flex-shrink: 0;
}

.sveaLogoCenterWrapper {
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-shrink: 0;
}

.sveaLogo50px {
  height: 50px;
}
.sveaLogo70px {
  height: 70px;
}

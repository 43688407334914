.inkassoBankIdLogoWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inkassoBankIdLogoFrame {
  margin: 10px 0px 30px 0px;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: #e4f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inkassoBankIdLogo {
  width: 50px;
  height: 50px;
}
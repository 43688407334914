.notAuthorizedMain {
  padding: 0;
  background-color: #f8f8f4;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

}

.notAuthorizedChild {
  padding-top: 20px;
  height: auto;
  display: flex;
  justify-content:center;
  align-items: flex-start;
  flex-shrink: 0;
}

.notAuthorizedHeader  {
  margin-bottom: 10px;
  font-family: Asap-SemiBold;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #434343;
  width: 100%;
}